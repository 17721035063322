import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {BpmRole} from '../model/bpm-role.model';

@Injectable()
export class RolesService {
  private serviceUrl: string;

  constructor(
    private http: HttpClient) {
    this.serviceUrl = `${environment.baseApiUrl}/bpmroles`;
  }

  public addBpmRoles(bpmRoles: JSON): Observable<Array<BpmRole>> {
    return this.http.post(`${this.serviceUrl}/add`, bpmRoles, {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body);
  }

  public getBpmRoles(): Observable<Array<BpmRole>> {
    return this.http.get(`${this.serviceUrl}/list`, {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body);
  }
}
