import {Injectable} from '@angular/core';
import {SessionStorageService} from 'ngx-webstorage';
import {CustomEventManager} from './custom-event-manager.service';
import {UIConfig} from '../../model/ui-config.model';

@Injectable()
export class StateStorageService {
  constructor(
    private eventManager: CustomEventManager,
    private $sessionStorage: SessionStorageService) {}

  getCurrentStep(): any {
    return this.$sessionStorage.retrieve('currentStep');
  }

  storeCurrentStep(step: any): any {
    this.$sessionStorage.store('currentStep', step);
    this.eventManager.broadcast({name: 'event.storage.step.config.changed'});
    return step;
  }

  getCurrentUser(): any {
    return this.$sessionStorage.retrieve('currentUser');
  }

    storeCurrentUser(user: any): any {
    this.$sessionStorage.store('currentUser', user);
    this.eventManager.broadcast({name: 'event.storage.user.changed'});
    return user;
  }

  getCurrentUIConfig(): any {
    return this.$sessionStorage.retrieve('currentUIConfig');
  }

  storeUIConfig(newUIConfig: UIConfig) {
    this.$sessionStorage.store('currentUIConfig', newUIConfig);
    this.eventManager.broadcast({name: 'event.ui.config.changed'});
    return newUIConfig;
  }

  getAuthenticationToken(): any {
    return this.$sessionStorage.retrieve('authenticationToken');
  }

  storeAuthenticationToken(token: any): any {
    this.$sessionStorage.store('authenticationToken', token);
    this.eventManager.broadcast({name: 'event.auth.token.changed'});
    return token;
  }

  clear() {
    this.$sessionStorage.clear();
  }

  updateUIVisibleActions(approve: boolean, reject: boolean, custom: boolean, allVisible?: boolean) {
    const currentConfig = <UIConfig>this.getCurrentUIConfig();
    if (currentConfig) {
      currentConfig.toolbarButtons.approve.hidden = approve;
      currentConfig.toolbarButtons.reject.hidden = reject;
      if (currentConfig.toolbarButtons.custom) {
        currentConfig.toolbarButtons.custom.hidden = custom;
      }
      if (allVisible !== undefined && allVisible !== null) {
        currentConfig.allVisible = allVisible;
      }
      this.storeUIConfig(currentConfig);
    }
  }
}
