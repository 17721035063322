import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import {Observable} from 'rxjs/Observable';
import {InstanceDetails} from '../model/instance-details.model';
import {MomentUtil} from '../../util/moment.util';

@Injectable()
export class InstanceService {
  private serviceUrl: string;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.baseApiUrl + '/instance';
  }

  getDetails(instanceId: string): Observable<InstanceDetails> {
    return this.http.get(this.serviceUrl + '/details/' + instanceId, {
      observe: 'response'
    })
      .map((res: HttpResponse<any>) => res.body)
      .map((obj) => MomentUtil.mapDates(obj, ['startDate', 'dataInchidere']));
  }

  getDetailsByCaseId(caseId: string): Observable<InstanceDetails> {
    return this.http.get(this.serviceUrl + '/by-case/' + caseId, {
      observe: 'response'
    })
      .map((res: HttpResponse<any>) => res.body)
      .map((obj) => MomentUtil.mapDates(obj, ['startDate', 'dataInchidere']));
  }

  // TODO: in backend
  getAudit(instanceId: string): any {
    return this.http.get(this.serviceUrl + '/audit/' + instanceId, {
    });
  }
}
