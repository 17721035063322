import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RolesService} from '../../services/roles.service';
import {ToastrService} from 'ngx-toastr';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';

@Component({
  selector: 'app-roles-component',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  @ViewChild('jsonEditor', {static: false})
  jsonEditor: JsonEditorComponent;

  payload: any;
  editorOptions: JsonEditorOptions;
  private isDirty = false;

  constructor(
    public activeModal: NgbActiveModal,
    private rolesService: RolesService,
    private toastService: ToastrService
  ) { }

  ngOnInit() {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
    this.editorOptions.mode = 'code';

    this.rolesService.getBpmRoles().subscribe(
      (roles) => {
        this.jsonEditor.change.subscribe(() => {
          this.isDirty = true;
        });
        this.jsonEditor.set(<any>roles);
      }
    );
  }

  saveRoles() {
    const json = this.jsonEditor.get();
    if (!this.jsonEditor.isValidJson() && !json) {
      this.toastService.warning('Nu ati adaugat nimic in lista de modificari!', 'Atentie!');
      return;
    }
    this.rolesService.addBpmRoles(json).subscribe(
      () => {
        this.toastService.success('Rolurile si membrii au fost adaugate cu succes!', 'Succes!');
      },
      () => {
        this.toastService.success('A aparut o eroare la salvare.', 'Eroare!');
      }
    );
  }

  isNewConfig(): boolean {
    if (this.jsonEditor === undefined) {
      return false;
    }
    if (!this.jsonEditor.isValidJson()) {
      return true;
    }
    return this.isDirty;
  }

  isValidConfig(): boolean {
    return this.jsonEditor === undefined ? false : this.jsonEditor.isValidJson();
  }

  closeModal() {
    this.activeModal.close('CLOSE');
  }

}
