import {Observable, throwError} from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {Injectable} from '@angular/core';
import {catchError, tap} from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private toastrService: ToastrService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const handleErrorEvent = (evt: HttpErrorResponse, alreadyHandled: boolean) => {

      // Don't handle reset-password errors here
      if (req.url.includes('reset-password')) {
        return;
      }

      // Skip handling authentication errors and 409 errors here
      if (evt.status === 403 || evt.status === 401 || evt.status === 409) {
        return;
      }

      if (alreadyHandled) {
        return;
      }

      let msg = 'A avut loc o eroare in sistem. Incercati din nou.';
      const body = evt.error;

      if (body && body.error) {
        msg = body.error.message || msg;
      }

      this.toastrService.error(msg, 'Eroare');
    };

    return next.handle(req).pipe(
      tap({
        next: (evt) => {
          if (evt instanceof HttpResponse) {
          }
        },
        error: (err) => {
          if (err instanceof HttpErrorResponse) {
            handleErrorEvent(err, false);
          }
        }
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 409 || req.url.includes('reset-password')) {
          return throwError(err);
        }
        // handleErrorEvent(err, false);
        return throwError(err);
      })
    );
  }
}
