// token-resolver.service.ts
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenResolverService implements Resolve<any> {
  private resolvedToken: string | null = null;

  constructor(private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): any {
    if (this.resolvedToken) {
      return this.resolvedToken;
    }
    const token = route.queryParamMap.get('token');

    if (!token) {
      this.router.navigate(['/login']);
      return null;
    }

    this.resolvedToken = token;
    return token;
  }
}
