import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {IOption} from 'ng-select';
import {FlowService} from '../../services/flow.service';
import {OrganizationService} from '../../services/organization.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {CaseService} from '../../services/case.service';
import {FlowType} from '../../model/flow-type.model';

@Component({
  selector: 'app-start-flow',
  templateUrl: './start-flow.component.html',
  styleUrls: ['./start-flow.component.scss']
})
export class StartFlowComponent implements OnInit, AfterViewInit {

  @Input()
  fromdCollectionId: string;

  flows: Array<FlowType> = [];

  directions: Array<IOption> = [];
  secondaryDirections: Array<IOption> = [];
  flowOptions: Array<IOption> = [];

  // form payload
  selectedFlow: any;
  selectedDirection: any;
  selectedSecondaryDirections: any[] = [];
  descriereDosar: string;
  numeParti: string;
  caen: string;
  cui: string;

  constructor(
    private activeModal: NgbActiveModal,
    private caseService: CaseService,
    private flowService: FlowService,
    private organizationService: OrganizationService,
    private toastService: ToastrService
  ) { }

  ngOnInit() {
    this.flowService.getFlowTypes().subscribe((flows) => {
      this.flows = flows;
      this.flowOptions = flows.map((f) => ({
        label: f.description,
        value: f.key
      }));
    });
  }

  ngAfterViewInit(): void {
  }

  closeModal(reason: string = 'CLOSE') {
    this.activeModal.close(reason);
  }

  startFlow() {
    const payload = {
      'directia': this.selectedDirection,
      'flowType': this.selectedFlow
    };
    if (this.fromdCollectionId) {
      payload['fromdCollectionId'] = this.fromdCollectionId;
    }
    if (this.selectedSecondaryDirections && this.selectedSecondaryDirections.length > 0) {
      payload['directiiSecundare'] = this.selectedSecondaryDirections.join(',');
    }
    if (this.descriereDosar) {
      payload['descriereDosar'] = this.descriereDosar;
    }
    if (this.numeParti) {
      payload['numeParti'] = this.numeParti;
    }
    if (this.caen) {
      payload['caen'] = this.caen;
    }
    if (this.cui) {
      payload['cui'] = this.cui;
    }
    this.caseService.dispatchNewCase(payload).subscribe((res: any) => {
      this.toastService.info(`Cazul cu numarul ${res.nud} a fost initiat cu succes in folderul : ${res.casePath}`, 'Succes!');
      this.closeModal('SUCCESS');
    }, () => {
      this.toastService.error(`A avut loc o eroare. Va rugam incercati din nou sau contactati administratorul.`, 'Eroare!');
    });
  }

  onFlowSelected(flowOption: IOption) {
    this.organizationService.getAllDirections(flowOption.value).subscribe((dirs) => {
      this.directions = dirs.map((d) => this.organizationService.mapDirectieToOption(d));
      const flowType = this.getFlowType(flowOption.value);
      if (flowType && flowType.directiiSuport) {
        this.secondaryDirections = dirs.map((d) => this.organizationService.mapDirectieToOption(d));
      } else {
        this.secondaryDirections = [];
      }
    });
  }

  getFlowType(key: string): FlowType {
    return this.flows.find((f) => f.key === key);
  }
}
