import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import {Observable} from 'rxjs/Observable';
import {TestRunState} from '../model/test-run-state.model';
import {TestRunPayload} from '../model/test-run-payload.model';
import {MomentUtil} from '../../util/moment.util';

@Injectable()
export class FlowTestRunnerService {
  private stateDateFields = ['startDate'];
  private serviceUrl: string;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.baseApiUrl + '/flow/test-runner';
  }

  startRunner(payload: TestRunPayload): Observable<TestRunState> {
    return this.http.post(`${this.serviceUrl}/start`, payload, {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body)
      .map((state) => this.mapStateDates(state));
  }

  cancelRunner(runnerId: string): Observable<TestRunState> {
    return this.http.post(`${this.serviceUrl}/cancel/${runnerId}`, {}, {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body)
      .map((state) => this.mapStateDates(state));
  }

  getRunnerState(runnerId: string): Observable<TestRunState> {
    return this.http.get(`${this.serviceUrl}/state/${runnerId}`, {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body)
      .map((state) => this.mapStateDates(state));
  }

  private mapStateDates(state: TestRunState): TestRunState {
    state = MomentUtil.mapDates(state, this.stateDateFields);
    return state;
  }
}
