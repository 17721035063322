import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserProfileComponent} from '../user-profile/user-profile.component';
import {User} from '../../model/user.model';
import {AccountService} from '../../services/account.service';
import {StateStorageService} from '../../services/core/state-storage.service';
import {WindowRefService} from '../../dom/window-ref.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  user: User;

  constructor(
    private accountService: AccountService,
    private storageService: StateStorageService,
    private windowService: WindowRefService,
    private modalService: NgbModal,
    private router: Router) {
  }

  ngOnInit() {
    this.loadAccount();
  }

  openUserMenu() {
    const modalRef = this.modalService.open(UserProfileComponent, {centered: true, size: 'lg'});
    modalRef.result.then(
      (result) => {
      },
      () => {
      });
  }

  logout() {
    this.storageService.clear();
    // this.windowService.logoutRedirect();
    this.router.navigate(['/login']);
  }

  private loadAccount() {
    this.accountService.getAccount().subscribe((acc) => {
      this.user = acc;
    });
  }
}
