export class Delegation {
  constructor(
    public id: number,
    public initialUser: string,
    public delegatedUser: string,
    public startDate: Date,
    public endDate: Date,
    public created: Date,
    public updated: Date,
    public reassign: boolean) {
  }
}
