import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordResetService } from '../shared/services/password-reset.service';
import { UiLoaderService } from '../shared/services/core/ui-loader.service';
import { filter, switchMap, take } from 'rxjs/operators';
import { MIN_PASS_LEN } from "../shared/constants";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  token: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  message: string = '';
  showNewPassword = false;
  showConfirmPassword = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private passwordResetService: PasswordResetService,
    private uiLoaderService: UiLoaderService
  ) {}

  ngOnInit() {
    this.uiLoaderService.isLoading$.pipe(
      filter(isLoading => !isLoading),
      take(1),
      switchMap(() => this.route.queryParams)
    ).subscribe(
      params => {
        this.token = params['token'];
        if (!this.token) {
          this.router.navigate(['/login']);
        } else {
        }
      },
      error => {
        console.error('Error retrieving token:', error);
        this.router.navigate(['/login']);
      }
    );
  }

  resetPassword() {
    if (!this.isPasswordValid) {
      this.message = 'Parolele nu se potrivesc sau parola nu are cel puțin 8 caractere.';
      return;
    }

    this.passwordResetService.resetPassword(this.token, this.newPassword).subscribe(
      () => {
        this.message = 'Parola a fost resetată cu succes. Acum vă puteți conecta cu noua parolă.';
        setTimeout(() => this.router.navigate(['/login']), 3000);
      },
      error => {
        this.message = 'Eroare: ' + error.error.message;
      }
    );
  }

  toggleNewPasswordVisibility() {
    this.showNewPassword = !this.showNewPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  get passwordsMatch() {
    return this.newPassword === this.confirmPassword;
  }

  get isPasswordValid() {
    return this.newPassword && this.confirmPassword &&
      this.newPassword === this.confirmPassword &&
      this.newPassword.length >= MIN_PASS_LEN;
  }
}
