import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CommentsService} from './services/comments.service';
import {InstanceService} from './services/instance.service';
import {OrganizationService} from './services/organization.service';
import {StateStorageService} from './services/core/state-storage.service';
import {TaskService} from './services/task.service';
import {AccountService} from './services/account.service';

import {HeaderComponent} from './ui/header/header.component';
import {FooterComponent} from './ui/footer/footer.component';
import {FlowService} from './services/flow.service';
import {WindowRefService} from './dom/window-ref.service';
import {LoginService} from './services/login.service';
import {CaseService} from './services/case.service';
import {MomentUtil} from '../util/moment.util';
import {CommentsComponent} from './ui/comments/comments.component';
import {DelegationService} from './services/delegation.service';
import {AttachmentService} from './services/attachment.service';
import {FileSizePipe} from './pipes/file-size.pipe';
import {UserProfileComponent} from './ui/user-profile/user-profile.component';
import {UserMenuComponent} from './ui/user-menu/user-menu.component';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {FileUploadModule} from 'ng2-file-upload';
import {AuditService} from './services/audit.service';
import {EditorService} from './services/editor.service';
import {FlowTestRunnerService} from './services/flow-test-runner.service';
import {RouterModule} from '@angular/router';
import {DelegationComponent} from '../tasklist/components/delegation/delegation.component';
import {RolesComponent} from './ui/roles/roles.component';
import {SelectModule} from 'ng-select';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import {TableModule} from 'primeng/table';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ActionsComponent} from './ui/actions/actions.component';
import {DmsDocumentService} from './services/dms-document.service';
import {LawArticleService} from './services/law-article.service';
import {ConfirmModalComponent} from './ui/confirm-modal/confirm-modal.component';
import {UiLoaderService} from './services/core/ui-loader.service';
import {DocPreviewComponent} from './ui/doc/doc-preview/doc-preview.component';
import {DocPreviewModalComponent} from './ui/doc/doc-preview-modal/doc-preview-modal.component';
import {RolesService} from './services/roles.service';
import {NgJsonEditorModule} from 'ang-jsoneditor';
import {AttachmentUploadModalComponent} from './ui/doc/doc-upload/attachment-upload-modal.component';
import {ToastContainerModule} from 'ngx-toastr';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {CalendarComponent} from './ui/calendar/calendar.component';
import {AccordionModule} from 'primeng/accordion';
import {EventsService} from './services/events.service';
import {StartFlowComponent} from './ui/start-flow/start-flow.component';
import {LoginComponent} from '../login/login.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {RegistryService} from './services/registry.service';
import {PasswordResetService} from './services/password-reset.service';
import {ResetPasswordComponent} from '../reset-password/reset-password.component';
import {UploadDocumentComponent} from "./ui/doc/doc-upload-bEntity/upload-document.component";

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    FileUploadModule,
    SelectModule,
    RouterModule,
    NgJsonEditorModule,
    // ngfaces modules
    CheckboxModule,
    CalendarModule,
    TableModule,
    DropdownModule,
    InputSwitchModule,
    DialogModule,
    ToastContainerModule,
    FullCalendarModule,
    AccordionModule,
    PdfViewerModule
  ],
  declarations: [
    ActionsComponent,
    FooterComponent,
    HeaderComponent,
    CommentsComponent,
    UserProfileComponent,
    UserMenuComponent,
    DelegationComponent,
    DocPreviewComponent,
    AttachmentUploadModalComponent,
    ConfirmModalComponent,
    DocPreviewModalComponent,
    FileSizePipe,
    StartFlowComponent,
    RolesComponent,
    CalendarComponent,
    LoginComponent,
    ResetPasswordComponent,
    UploadDocumentComponent
  ],
  providers: [
    AttachmentService,
    AccountService,
    AuditService,
    CaseService,
    CommentsService,
    DelegationService,
    EditorService,
    FlowService,
    FlowTestRunnerService,
    InstanceService,
    LawArticleService,
    LoginService,
    OrganizationService,
    RolesService,
    TaskService,
    StateStorageService,
    WindowRefService,
    DmsDocumentService,
    UiLoaderService,
    MomentUtil,
    EventsService,
    NgbActiveModal,
    RegistryService,
    PasswordResetService,
  ],
  exports: [
    ActionsComponent,
    FooterComponent,
    HeaderComponent,
    CommentsComponent,
    UserProfileComponent,
    UserMenuComponent,
    DocPreviewComponent,
    AttachmentUploadModalComponent,
    ConfirmModalComponent,
    DocPreviewModalComponent,
    FileSizePipe,
    CalendarComponent,
    UploadDocumentComponent
  ],
  entryComponents: [
    ActionsComponent,
    UserProfileComponent,
    DelegationComponent,
    RolesComponent,
    DocPreviewComponent,
    AttachmentUploadModalComponent,
    ConfirmModalComponent,
    DocPreviewModalComponent,
    CalendarComponent,
    StartFlowComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
