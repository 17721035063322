import { Component, OnInit } from '@angular/core';
import { LoginService } from "../shared/services/login.service";
import { StateStorageService } from "../shared/services/core/state-storage.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie";
import { AccountService } from "../shared/services/account.service";
import { PasswordResetService } from "../shared/services/password-reset.service";
import {NgxUiLoaderService} from "ngx-ui-loader";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: any = {};
  isLoginFailed = false;
  errorMessage = '';
  badCreds = false;
  isRecoveringPassword = false;
  recoveryEmail = '';
  recoveryMessage = '';
  isSubmitting = false;

  constructor(
    private loginService: LoginService,
    private storage: StateStorageService,
    private router: Router,
    private cookieService: CookieService,
    private accountService: AccountService,
    private activeRoute: ActivatedRoute,
    private passwordResetService: PasswordResetService,
    private loaderService: NgxUiLoaderService
  ) {
    this.loaderService.stop();
    this.activeRoute.paramMap.subscribe(params => {
      const type = params.get('type');
      if (type && type === 'bad') {
        this.badCreds = true;
      }
    });
    this.loaderService.stop();

  }

  ngOnInit() {}

  onSubmit() {
    this.isLoginFailed = false;

    this.loaderService.start();
    this.loginService.login(this.form).subscribe(
      token => {
        this.isLoginFailed = false;
        this.cookieService.put('jwt-authentication', token.token);
        this.storage.storeAuthenticationToken(token.token);
        this.router.navigate(['/tasklist/home']);
        this.loaderService.stop();
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
        this.loaderService.stop();

      }
    );
  }

  recoverPassword() {
    this.isRecoveringPassword = true;
    this.recoveryMessage = '';
  }

  submitRecoveryEmail() {
    this.isSubmitting = true;
    this.passwordResetService.requestPasswordReset(this.recoveryEmail).subscribe(
      () => {
        this.recoveryMessage = 'Vă rugăm să verificați căsuța de e-mail pentru instrucțiuni de resetare a parolei.';
        this.isRecoveringPassword = false;
        this.recoveryEmail = '';
        this.isSubmitting = false;
      },
      error => {
        this.recoveryMessage = 'A apărut o eroare. Vă rugăm să încercați din nou.';
        this.isRecoveringPassword = false;
        this.isSubmitting = false;
      }
    );
  }


  cancelRecovery() {
    this.isRecoveringPassword = false;
    this.recoveryEmail = '';
    this.recoveryMessage = '';
  }
}
