import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {MomentUtil} from '../../util/moment.util';
import {Delegation} from '../model/delegation.model';

@Injectable()
export class DelegationService {
  private serviceUrl: string;
  private delegationDateFields = ['startDate', 'endDate', 'created', 'updated'];

  constructor(
    private http: HttpClient) {
    this.serviceUrl = `${environment.baseApiUrl}/delegation`;
  }

  public create(d: any): Observable<Delegation> {
    return this.http.post(`${this.serviceUrl}`, d, {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body)
      .map((obj) => { obj = this.mapDelegationDates(obj); return obj; });
  }

  public getAll(): Observable<Array<Delegation>> {
    return this.http.get(this.serviceUrl + '/all', {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body)
      .map((obj) => { obj = this.mapDelegationsDates(obj); return obj; });
  }

  public delete(delegationId: number) {
    return this.http.delete(`${this.serviceUrl}/${delegationId}`, { observe: 'response' })
    .map((res: HttpResponse<any>) => res.body);
  }

  /**
   * Handles obj and arrays.
   * @param tasks
   */
  private mapDelegationsDates(delegations: Array<Delegation>): Array<Delegation> {
    return delegations.map((d) => this.mapDelegationDates(d));
  }

  private mapDelegationDates(delegation: Delegation): Delegation {
    delegation = MomentUtil.mapDates(delegation, this.delegationDateFields);
    return delegation;
  }

}
