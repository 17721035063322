import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-doc-preview',
  templateUrl: './doc-preview.component.html',
  styleUrls: ['./doc-preview.component.scss']
})
export class DocPreviewComponent implements OnInit {

  @Input()
  set url(urlString: string) {
    if (urlString) {
      this.previewUrl = urlString;//this.sanitizer.bypassSecurityTrustResourceUrl(urlString);
    }
  }
  previewUrl: string;//SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
  }

}
