import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {PasswordResetService} from '../../shared/services/password-reset.service';

@Injectable({
  providedIn: 'root'
})
export class TokenValidationGuard implements CanActivate {
  constructor(
    private passwordResetService: PasswordResetService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const token = route.queryParamMap.get('token');

    if (!token) {
      this.showTokenExpiredWarning();
      this.router.navigate(['/login']);
      return Observable.of(false);
    }

    return this.passwordResetService.validateToken(token).pipe(
      map(isValid => {
        if (!isValid) {
          this.showTokenExpiredWarning();
          this.router.navigate(['/login']);
        }
        return isValid;
      }),
      catchError(() => {
        this.showTokenExpiredWarning();
        this.router.navigate(['/login']);
        return Observable.of(false);
      })
    );
  }

  private showTokenExpiredWarning() {
    this.toastr.warning(
      'Linkul de resetare a parolei a expirat sau este invalid. Vă rugăm să solicitați un nou link de resetare a parolei.',
      'Atenție',
      {
        timeOut: 5000,
        positionClass: 'toast-top-center'
      }
    );
  }
}
