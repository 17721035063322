import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {DmsDocument} from '../model/dms-document.model';
import {DmsDocumentFilter} from '../model/dms-document-filter.model';
import {MomentUtil} from '../../util/moment.util';
import {DmsCollectionDetails} from '../model/dms-collection-details.model';
import { NotificationService } from './notification.service';
import { CustomEventManager } from './core/custom-event-manager.service';
import {DocType} from '../model/doc-type.model';
import {DmsDocumentDetails} from '../model/dms-document-details.model';
import {CopyModel} from "../model/copy.model";

@Injectable()
export class DmsDocumentService {
  private serviceUrl: string;
  private docDateFields = ['createDate', 'lastUpdateDate'];

  constructor(
    private http: HttpClient,
    private eventManager: CustomEventManager) {
    this.serviceUrl = `${environment.baseApiUrl}/document`;
  }

  getDocTypes(): Observable<Array<DocType>> {
    return this.http.get(`${this.serviceUrl}/doctype/all`, {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body);
  }

  createCollection(parentCollectionId: string, newColName: string): Observable<DmsCollectionDetails> {
    const payload = {
      'title': newColName
    };
    return this.http.post(`${this.serviceUrl}/collection/${parentCollectionId}`, payload, {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body);
  }

  upload(payload: any): Observable<HttpResponse<DmsCollectionDetails>> {
    return this.http.post(`${this.serviceUrl}/upload`, payload, {observe: 'response'})
      .map((res: HttpResponse<any>) => res);
  }

  getAll(filter: DmsDocumentFilter): Observable<Array<DmsDocument>> {
    return this.http.post(this.serviceUrl + '/all', filter, {
        observe: 'response'
      })
      .map((res: HttpResponse<any>) => res.body)
      .map((obj) => this.mapDocumentsDates(obj));
  }

  copy(copyModel: CopyModel): Observable<any> {
    return this.http.post(this.serviceUrl + '/copy', copyModel, {
      observe: 'response'
    });
  }

  getRecent(): Observable<Array<DmsDocument>> {
    this.eventManager.broadcast({name: NotificationService.NOTIFICATIONS_REQUEST_EVENT});
    return this.http.get(this.serviceUrl + '/filter/recent', {
      observe: 'response'
    })
    .map((res: HttpResponse<any>) => res.body)
    .map((obj) => this.mapDocumentsDates(obj));
  }

  getDocumentDetails(dmsId: string): Observable<DmsDocumentDetails> {
    return this.http.get(`${this.serviceUrl}/details/document/${dmsId}`, {
      observe: 'response'
    })
    .map((res: HttpResponse<any>) => res.body)
    .map((obj) => this.mapDocumentDetailDates(obj));
  }

  getCollectionDetails(id: string, nud: boolean): Observable<DmsCollectionDetails> {
    this.eventManager.broadcast({name: NotificationService.NOTIFICATIONS_REQUEST_EVENT});
    const path = nud ? '/details/collection/nud/' : '/details/collection/';
    return this.http.get(`${this.serviceUrl}${path}${id}`, {
      observe: 'response'
    })
    .map((res: HttpResponse<any>) => res.body)
    .map((obj) => this.mapCollectionDetailsDates(obj));
  }

  getPersonalCollectionDetails(): Observable<DmsCollectionDetails> {
    const path = '/details/collection/personal';
    return this.http.get(`${this.serviceUrl}${path}`, {
      observe: 'response'
    })
      .map((res: HttpResponse<any>) => res.body)
      .map((obj) => this.mapCollectionDetailsDates(obj));
  }

  delete(dmsId: string): Observable<DmsDocumentDetails>  {
    return this.http.delete(`${this.serviceUrl}/${dmsId}`, {
      observe: 'response'
    })
    .map((res: HttpResponse<any>) => res.body)
    .map((obj) => this.mapDocumentDetailDates(obj));
  }

  /**
   * Handles obj and arrays.
   * @param tasks
   */
  private mapDocumentsDates(docs: Array<DmsDocument>): Array<DmsDocument> {
    return docs.map((d) => this.mapDocumentDates(d));
  }

  private mapDocumentDates(doc: DmsDocument): DmsDocument {
    doc = MomentUtil.mapDates(doc, this.docDateFields);
    return doc;
  }

  private mapDocumentDetailDates(doc: DmsDocumentDetails): DmsDocumentDetails {
    doc = MomentUtil.mapDates(doc, this.docDateFields);
    return doc;
  }

  private mapCollectionDetailsDates(col: DmsCollectionDetails): DmsCollectionDetails {
    if (col == null) {
      return null;
    }
    col = MomentUtil.mapDates(col, this.docDateFields);
    col.documents = this.mapDocumentsDates(col.documents);
    col.children = col.children.map(c => this.mapCollectionDetailsDates(c));
    return col;
  }
}
