import {Injector, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';

import {AppComponent} from './app.component';
import {Router, RouterModule} from '@angular/router';
import {appRoutes} from './app.route';
import {SharedModule} from './shared/shared.module';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {BrowserModule} from '@angular/platform-browser';
import {StateStorageService} from './shared/services/core/state-storage.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './shared/http/auth.interceptor';
import {NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER} from 'ngx-ui-loader';
import {CookieModule, CookieService} from 'ngx-cookie';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule, ToastrService} from 'ngx-toastr';
import {HttpErrorInterceptor} from './shared/http/http-error.interceptor';
import {UiLoaderService} from './shared/services/core/ui-loader.service';
import {UILoaderInterceptor} from './shared/http/ui-loader.interceptor';
import {NotificationService} from './shared/services/notification.service';
import {CustomEventManager} from './shared/services/core/custom-event-manager.service';
import {PeService} from './tasklist/service/pe.service';
import {SwalService} from './shared/ui/swal.service';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsPosition: POSITION.centerCenter,
  bgsSize: 40,
  bgsType: SPINNER.ballSpinFadeRotating,
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 10, // progress bar thickness
  fgsColor: 'red'
};

@NgModule({
  imports: [
    CookieModule.forRoot(),
    RouterModule.forRoot(appRoutes, { useHash: true }),
    NgxWebstorageModule.forRoot({ prefix: 'bpm', separator: '-'}),
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
    })
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UILoaderInterceptor,
      multi: true,
      deps: [UiLoaderService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [StateStorageService, CookieService, Router]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [ToastrService]
    },
    NotificationService,
    CustomEventManager,
    PeService,
    SwalService
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;
  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
