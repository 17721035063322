import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../../environments/environment';
import {RegistryModel} from '../model/registry.model';

@Injectable()
export class RegistryService {
  private serviceUrl: string;

  constructor(private http: HttpClient) {
    this.serviceUrl = `${environment.baseApiUrl}/registry`;
  }

  public getRegistries(): Observable<Array<RegistryModel>> {
    return this.http.get(`${this.serviceUrl}`, {observe: 'response'})
      .map((res: HttpResponse<Array<RegistryModel>>) => res.body);
  }

  public saveRegistry(registryModel: RegistryModel): Observable<RegistryModel> {
    return this.http.post(`${this.serviceUrl}`, registryModel, {observe: 'response'})
      .map((res: HttpResponse<RegistryModel>) => res.body);
  }

  public deleteRegistry(registryModel: RegistryModel): Observable<any> {
    return this.http.delete(`${this.serviceUrl}/${registryModel.id}`,  {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body);
  }
}
