import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AccountService} from '../../services/account.service';
import {User} from '../../model/user.model';
import {ToastrService} from 'ngx-toastr';
import {StateStorageService} from '../../services/core/state-storage.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  APP_ROLES = [
    'ROLE_ADMINISTRATOR',
    'ROLE_DIRECTOR_GENERAL',
    'ROLE_SEF_EO',
    'ROLE_SEF_SACH',
    'ROLE_RP',
    'ROLE_RC',
    'ROLE_REGISTRATOR',
    'ROLE_USER',
  ];

  profile: User = new User(null, null, null, null, null, null, null, [], null, false, false);
  institutii: any[] = [];
  functii: any[] = [];
  roles: string;

  currentPassword: string;
  newPassword: string;
  confirmPassword: string;

  constructor(
    private activeModal: NgbActiveModal,
    private accountService: AccountService,
    private toastService: ToastrService,
    private storage: StateStorageService
  ) {}

  ngOnInit() {
    this.loadAccount();
    this.loadFunctii();
    this.loadInstitutii();
  }

  closeModal() {
    this.activeModal.close('CLOSE');
  }

  saveAccount() {
    if (!this.formValid()) {
      this.toastService.error('Completați câmpurile obligatorii!', 'Eroare!');
      return;
    }

    if (this.newPassword) {
      if (this.newPassword !== this.confirmPassword) {
        this.toastService.error('Parola noua si confirmarea parolei nu se potrivesc.', 'Eroare!');
        return;
      }
      this.changePassword();
    } else {
      this.updateProfile();
    }
  }
  private loadFunctii() {
    this.accountService.getFunctii().subscribe(
      (functii) => {
        this.functii = functii;
      },
      (error) => {
        console.error('Error loading functii', error);
      }
    );
  }

  private loadInstitutii() {
    this.accountService.getInstitutii().subscribe(
      (institutii) => {
        this.institutii = institutii;
      },
      (error) => {
        console.error('Error loading institutii', error);
      }
    );
  }

  private changePassword() {
    this.accountService.changePassword(this.currentPassword, this.newPassword).subscribe(
      () => {
        this.toastService.success('Parola a fost schimbată cu succes.', 'Succes!');
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.updateProfile();
      },
      (error) => {
        this.toastService.error(error.error || 'Eroare la schimbarea parolei.', 'Eroare!');
      }
    );
  }

  private updateProfile() {
    this.accountService.saveAccount(this.profile).subscribe(
      (acc) => {
        this.profile = this.filterRoles(acc);
        this.storage.storeCurrentUser(acc);
        this.toastService.info('Profilul a fost salvat cu succes.', 'Succes!');
      },
      (_) => {
        this.toastService.error('Eroare la salvarea profilului.', 'Eroare!');
      }
    );
  }

  private loadAccount() {
    this.accountService.getAccount().subscribe(
      (acc) => {
        this.profile = this.filterRoles(acc);
      },
      (_) => {
        this.toastService.error('Eroare la încărcarea profilului.', 'Eroare!');
      }
    );
  }

  filterRoles(account: User): User {
    if (!account || !account.authorities) {
      return account;
    }

    this.roles = '';
    account.authorities.forEach(authority => {
      if (this.APP_ROLES.indexOf(authority) >= 0) {
        if (this.roles.length) {
          this.roles += ', ';
        }
        this.roles += authority;
      }
    });

    return account;
  }

  emailIsValid() {
    if (!this.profile || !this.profile.email) {
      return false;
    }
    return new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(this.profile.email);
  }

  formValid() {
    return this.profile && this.profile.email && this.profile.nume && this.emailIsValid();
  }
}
