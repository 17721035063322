import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {BPMUser} from '../model/bpmuser.model';
import {AssigneeModel} from "../model/assignee.model";

@Injectable()
export class OrganizationService {
  private serviceUrl: string;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.baseApiUrl + '/organization';
  }

  getAllDirections(flow?: string): Observable<Array<any>> {
    let url = this.serviceUrl;
    if (flow) {
      url += `/flow/${flow}/directions`;
    } else {
      url += `/directions`;
    }
    return this.http.get(url, {
      observe: 'response'
    })
    .map((res: HttpResponse<any>) => res.body);
  }

  getAllDirectionsExcept(instanceId: any): Observable<Array<any>> {
    return this.http.get(this.serviceUrl + `/directions/except/${instanceId}`, {
        observe: 'response'
      })
      .map((res: HttpResponse<any>) => res.body);
  }

  getAllUsers(): Observable<Array<BPMUser>> {
    return this.http.get(`${this.serviceUrl}/users/all`, {
        observe: 'response'
      })
      .map((res: HttpResponse<any>) => res.body);
  }

  getAllMembers(): Observable<Array<BPMUser>> {
    return this.http.get(`${this.serviceUrl}/members/all`, {
      observe: 'response'
    })
      .map((res: HttpResponse<any>) => res.body);
  }

  getMembers(instanceId: string, directie?: string): Observable<Array<BPMUser>> {
    if (directie === undefined) {
      return this.http.get(`${this.serviceUrl}/instance/${instanceId}/members`, {
          observe: 'response'
        })
        .map((res: HttpResponse<any>) => res.body);
    } else {
      return this.http.get(`${this.serviceUrl}/direction/${directie}/members`, {
          observe: 'response'
        })
        .map((res: HttpResponse<any>) => res.body);
    }
  }

  getGroupUsers(group: string): Observable<Array<BPMUser>> {
    const payload = {
      'group': group
    };
    return this.http.post(`${this.serviceUrl}/members/group`, payload, {
      observe: 'response'
    })
    .map((res: HttpResponse<any>) => res.body);
  }

  public mapUserToOption(user: BPMUser): any {
    let groups = '';
    if (user.groups) {
      groups = ' ( ' + user.groups.join(', ') + ' )';
    }
    return {
      label: user.name + groups,
      value: user.username
    };
  }

  mapDirectieToOption(d: any) {
    return {
      label: d.cod + ' ( ' + d.description + ' )',
      value: d.cod
    };
  }

  getAssignees(caseId): Observable<Array<AssigneeModel>> {
    return this.http.get(`${this.serviceUrl}/assignees/${caseId}`, {
      observe: 'response'
    })
        .map((res: HttpResponse<Array<AssigneeModel>>) => res.body);
  }
}
