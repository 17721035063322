import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class LawArticleService {
  private serviceUrl: string;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.baseApiUrl + '/law-article';
  }

  getActiveLawArticles(): Observable<Array<any>> {
    return this.http.get(this.serviceUrl + `/active`, {
      observe: 'response'
    })
    .map((res: HttpResponse<any>) => res.body);
  }

  public mapArticleToOption(article: any): any {
    return {
      label: article.description,
      value: article.cod
    };
  }
}
