import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CustomEventManager} from '../../services/core/custom-event-manager.service';
import {UIConfig} from '../../model/ui-config.model';
import {StateStorageService} from '../../services/core/state-storage.service';
import {Subscription} from 'rxjs';
import {Router} from "@angular/router";

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit, OnDestroy {
  @Input() caseClosed = false;

  uiConfig = new UIConfig(UIConfig.defaultToolbarConfig(), false, false);
  sub = new Subscription();

  constructor(private eventManager: CustomEventManager,
              private storage: StateStorageService,
              private router: Router) {
  }

  ngOnInit() {
    const uiConfig = this.storage.getCurrentUIConfig();
    if (uiConfig) {
      this.uiConfig = uiConfig;
    }
    this.sub.add(this.eventManager.subscribe('event.ui.config.changed', (event) => {
      this.uiConfig = this.storage.getCurrentUIConfig();
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  handleApprove() {
    this.eventManager.broadcast({name: 'event.ui.step.approve'});
  }

  handleCustomButton() {
    this.eventManager.broadcast({name: 'event.ui.step.approve.custom'});
  }

  handleReject() {
    this.eventManager.broadcast({name: 'event.ui.step.reject'});
  }

  handleBack() {
    const firstSplit = this.router.url.split('?');
    const routeSplit = firstSplit[0].split('/');
    this.router.navigate(['/tasklist/' + routeSplit[routeSplit.length - 1]]);
  }
}
