import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CommentsService} from '../../services/comments.service';
import {CustomEventManager} from '../../services/core/custom-event-manager.service';
import {StateStorageService} from '../../services/core/state-storage.service';
import {UserComment} from '../../model/comment.model';
import {Step} from '../../model/step.model';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy {
  public MAX_COMMENT_LENGTH = 255;

  @Input()
  set caseId(val: string) {
    this.setCaseId(val);
  }
  @Input()
  set step(val: Step) {
    this.setStep(val);
  }
  _step: Step;
  _caseId: string;
  _readOnly = false;
  _isStepView = true;
  comment: string;
  comments: UserComment[];

  constructor(protected commentsService: CommentsService,
              protected eventManager: CustomEventManager,
              protected storage: StateStorageService,
              protected toastService: ToastrService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  loadComments() {
    if (!this._isStepView) {
      this.commentsService.getAllCommentsByCaseId(this._caseId).subscribe((comments) => {
        this.comments = comments;
      });
    } else {
      this.commentsService.getAllCommentsByInstanceIdAndStepId(this._step.instanceId, this._step.stepId).subscribe((comments) => {
        this.comments = comments;
      });
    }
  }

  addComment() {
    if (this.comment && this.comment.length > 3) {
      const step = this._step;
      const commentPayload = new UserComment(
        step.instanceId,
        step.taskId,
        step.stepId,
        step.acquiredBy,
        this.comment,
        null,
        null
      );
      this.commentsService.addComment(commentPayload).subscribe( (savedComment) => {
        this.comments.unshift(savedComment);
      });
      this.comment = '';
    } else {
      this.toastService.warning('Comentariul trebuie sa aiba cel putin 4 caractere lungime!', 'Atentie!');
    }
  }

  setCaseId(caseId: string) {
    this._caseId = caseId;
    this._isStepView = false;
    this._readOnly = true;
    this.loadComments();
  }

  setStep(step: Step) {
    this._step = step;
    this._isStepView = true;
    this._readOnly = false;
    this.loadComments();
  }
}
