import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../model/user.model';
import {Notification} from '../../model/notification.model';
import {Subscription} from 'rxjs';
import {CustomEventManager} from '../../services/core/custom-event-manager.service';
import {AccountService} from '../../services/account.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  showMenu = false;
  @Input()
  showToolbar = false;
  @Input()
  caseClosed = false;

  user: User;
  subscription = new Subscription();
  displayNotifications = false;
  notifications: Array<Notification> = [];

  collapsed: boolean;

  constructor(
    private accountService: AccountService,
    private eventManager: CustomEventManager,
    private modalService: NgbModal,
    private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.accountService.getAccount().subscribe(u => this.user = u);
    this.subscription.add(this.eventManager.subscribe('event.storage.user.changed', (event) => {
      this.accountService.getAccount().subscribe(u => this.user = u);
    }));
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getUnseenNotificationsCount() {
    return this.notificationService.getUnseenNotificationsCount();
  }

  getNotificationsCount() {
    return this.notificationService.getNotificationsCount();
  }

  showNotifications() {
    this.displayNotifications = !this.displayNotifications;
    if (this.displayNotifications) {
      this.notifications = this.notificationService.getCurrentNotifications();
    }
  }

  onNotificationSeen(notif: Notification) {
    this.notificationService.markNotificationSeen(notif.id).subscribe(res => {
      notif.sent = true;
    });
  }

  trackByNotificationFn(index, item) {
    return item.id;
  }

  onMarkAllNotificationsAsSeen() {
    this.notifications.forEach((notif) => {
      this.notificationService.markNotificationSeen(notif.id).subscribe((res) => {
        notif.sent = true;
      });
    });
  }

  goToRapoarte() {
    window.open('https://www.example.com', '_blank');
  }

  hide() {
    this.collapsed = true;
  }

  show() {
    this.collapsed = false;
  }
}
