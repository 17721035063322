import {TipEntitateExterna} from "./enums/tip-entitate-externa.enum";
import {CaleTransmitere} from "./enums/cale-transmitere.enum";

export class Attachment {
  constructor(
    public instanceId: string,
    public stepName: string,
    public type: string,
    public name: string,
    public dmsId: string,
    public previewUrl: boolean,
    public contentType: string,
    // Entitate externa
    public nume: string,
    public prenume: string,
    public denumire: string,
    public email: string,
    public adresa: string,
    public telefon: string,
    public cui: string,
    public tipEntitateExterna: TipEntitateExterna,
    public caleTransmitere: CaleTransmitere
  ) {}
}
