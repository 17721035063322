import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {UserComment} from '../model/comment.model';
import {MomentUtil} from '../../util/moment.util';

@Injectable()
export class CommentsService {
  private serviceUrl: string;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.baseApiUrl + '/comment';
  }

  addComment(comment: UserComment): Observable<UserComment> {
    return this.http.post(this.serviceUrl, comment, {
        observe: 'response'
      })
      .map((res: HttpResponse<any>) => res.body)
      .map((obj) => MomentUtil.mapDates(obj, ['created', 'updated']));
  }

  getAllCommentsByCaseId(caseId: any): Observable<Array<UserComment>> {
    return this.http.get(this.serviceUrl + '/all/case/' + caseId, {
        observe: 'response'
      })
      .map((res: HttpResponse<any>) => res.body)
      .map((obj) => MomentUtil.mapDates(obj, ['created', 'updated']));
  }

  getAllCommentsByInstanceIdAndStepId(instanceId: any, stepId: any): Observable<Array<UserComment>> {
    return this.http.get(this.serviceUrl + '/all/instance/' + instanceId + '/step/' + stepId, {
        observe: 'response'
      })
      .map((res: HttpResponse<any>) => res.body)
      .map((obj) => MomentUtil.mapDates(obj, ['created', 'updated']));
  }

}
