import * as moment from 'moment';
import {Moment} from 'moment';

export class MomentUtil {

  static compareDates(s1: Date, s2: Date) {
    const d1 = moment(s1);
    const d2 = moment(s2);
    const v1 = d1.toDate().valueOf();
    const v2 = d2.toDate().valueOf();
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  static compareDateStrings(s1: string, s2: string, format: string) {
    const d1 = moment(s1, format);
    const d2 = moment(s2, format);
    const v1 = d1.toDate().valueOf();
    const v2 = d2.toDate().valueOf();
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  static convertFromUtcToLocal(date: Date) {
    if (date) {
      return moment.utc(date).toDate();
    }
  }

  static today() {
    return moment();
  }

  static inAdvance(numberOfDays: number) {
    return moment().add(numberOfDays, 'days');
  }

  static formatDateTime(date: Date) {
    if (date) {
      return MomentUtil.formatCustom(date, 'DD-MM-YYYY HH:mm');
    }

    return '-';
  }

  static formatDate(date: Date) {
    if (date) {
      return MomentUtil.formatCustom(date, 'DD-MM-YYYY');
    }
    return '-';
  }

  static formatCustom(date: Date, pattern) {
    if (date) {
      return MomentUtil.getMoment(date).format(pattern);
    }

    return '';
  }

  static daysUntil(date: Date) {
    return MomentUtil.getMoment(date).diff(MomentUtil.getMoment(new Date()), 'days') + 1;
  }

  static getMoment(date: Date): Moment {
    return moment(date);
  }

  static parseDate(dateString: string) {
    const mo = moment(dateString, 'DD-MM-YYYY');
    const d = mo.toDate();
    return d;
  }

  static isAfter(startDate: Date, endDate: Date) {
    if (startDate && endDate) {
      return moment(endDate).isAfter(startDate, 'ms');
    }
  }

  static isBefore(startDate: Date, endDate: Date) {
    if (startDate && endDate) {
      return moment(endDate).isBefore(startDate, 'ms');
    }
  }

  static mapDates(obj: any, dateFields: string[]): any {
    if (obj instanceof Array) {
      return obj.map((o) => this.mapDates(o, dateFields));
    }
    dateFields.forEach((dateField) => {
      if (!!obj[dateField]) {
        obj[dateField] = new Date(obj[dateField]);
      }
    });
    return obj;
  }

}
