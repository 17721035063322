import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from '../../services/account.service';
import { User } from '../../model/user.model';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { StateStorageService } from '../../services/core/state-storage.service';
import { UiLoaderService } from '../../services/core/ui-loader.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  ACCOUNT_API_URL = environment.baseApiUrl + '/account';
  uploaderDigitalSignature: FileUploader;
  uploaderHandSignature: FileUploader;
  profile: User = new User(null, null, null, null, null, null, null, [], null, false, false);

  institutii: any[] = [];
  functii: any[] = [];

  currentPassword: string;
  newPassword: string;
  confirmPassword: string;

  constructor(
    private activeModal: NgbActiveModal,
    private accountService: AccountService,
    private toastService: ToastrService,
    private storage: StateStorageService,
    private uiLoaderService: UiLoaderService
  ) {}

  ngOnInit() {
    this.loadAccount();
    this.loadFunctii();
    this.loadInstitutii();
    this.initDigitalSignatureUploader();
    this.initHandSignatureUploader();
  }

  closeModal() {
    this.activeModal.close('CLOSE');
  }

  saveAccount() {
    if (!this.formValid()) {
      this.toastService.error('Completați câmpurile obligatorii!', 'Eroare!');
      return;
    }

    if (this.newPassword) {
      if (this.newPassword !== this.confirmPassword) {
        this.toastService.error('Parola noua si confirmarea parolei nu se potrivesc.', 'Eroare!');
        return;
      }
      this.changePassword();
    } else {
      this.updateProfile();
    }
  }
  private loadFunctii() {
    this.accountService.getFunctii().subscribe(
      (functii) => {
        this.functii = functii;
      },
      (error) => {
        console.error('Error loading functii', error);
      }
    );
  }

  private loadInstitutii() {
    this.accountService.getInstitutii().subscribe(
      (institutii) => {
        this.institutii = institutii;
      },
      (error) => {
        console.error('Error loading institutii', error);
      }
    );
  }
  private changePassword() {
    this.accountService.changePassword(this.currentPassword, this.newPassword).subscribe(
      () => {
        this.toastService.success('Parola a fost schimbată cu succes.', 'Succes!');
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.updateProfile();
      },
      (error) => {
        this.toastService.error(error.error || 'Eroare la schimbarea parolei.', 'Eroare!');
      }
    );
  }

  private updateProfile() {
    this.accountService.saveAccount(this.profile).subscribe(
      (acc) => {
        this.profile = acc;
        this.storage.storeCurrentUser(acc);
        this.toastService.info('Profilul a fost salvat cu succes.', 'Succes!');
      },
      (error) => {
        this.toastService.error('Eroare la salvarea profilului.', 'Eroare!');
      }
    );
  }

  private loadAccount() {
    this.accountService.getAccount().subscribe(
      (acc) => {
        this.profile = acc;
        if (!this.profile.roluri) {
          this.profile.roluri = [];
        }
      },
      (error) => {
        this.toastService.error('Eroare la încărcarea profilului.', 'Eroare!');
      }
    );
  }

  private initDigitalSignatureUploader() {
    this.uploaderDigitalSignature = new FileUploader({
      queueLimit: 1,
      autoUpload: true,
      method: 'POST',
      isHTML5: true,
      itemAlias: 'digital-signature-keystore',
      url: this.ACCOUNT_API_URL + '/digital-signature'
    });

    this.uploaderDigitalSignature.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
      this.uiLoaderService.requestStarted('ds-uploader');
    };

    this.uploaderDigitalSignature.onCompleteItem = (item, response, status, headers) => {
      this.uiLoaderService.requestFinished('ds-uploader');
      this.uploaderDigitalSignature.clearQueue();
      let error: string;
      if (status !== 200) {
        if (response && response.length > 0) {
          error = JSON.parse(response).error.message;
        } else {
          error = 'Eroarea la incarcare fisierului de semnatura digitala!';
        }
        this.toastService.error(error, 'Eroare!');
      } else {
        this.profile = JSON.parse(response);
        this.storage.storeCurrentUser(this.profile);
        this.toastService.info('Cheia de semnare digitala a fost incarcata cu succes.', 'Succes!');
      }
    };
  }

  private initHandSignatureUploader() {
    this.uploaderHandSignature = new FileUploader({
      queueLimit: 1,
      autoUpload: true,
      method: 'POST',
      isHTML5: true,
      itemAlias: 'hand-signature-file',
      url: this.ACCOUNT_API_URL + '/hand-signature'
    });

    this.uploaderHandSignature.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
      this.uiLoaderService.requestStarted('hs-uploader');
    };

    this.uploaderHandSignature.onCompleteItem = (item, response, status, headers) => {
      this.uiLoaderService.requestFinished('hs-uploader');
      this.uploaderHandSignature.clearQueue();
      let error: string;
      if (status !== 200) {
        if (response && response.length > 0) {
          error = JSON.parse(response).error.message;
        } else {
          error = 'Eroarea la incarcarea fisierul de semnatura olografa!';
        }
        this.toastService.error(error, 'Eroare!');
      } else {
        this.profile = JSON.parse(response);
        this.storage.storeCurrentUser(this.profile);
        this.toastService.info('Fisierul cu semnatura olografa a fost incarcat cu succes.', 'Succes!');
      }
    };
  }

  emailIsValid() {
    if (!this.profile || !this.profile.email) {
      return false;
    }
    return new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(this.profile.email);
  }

  formValid() {
    return this.profile && this.profile.email && this.profile.nume && this.emailIsValid();
  }
}
