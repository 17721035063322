import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {NewDosarModel} from '../../flow/model/new-dosar.model';
import {DosarSemnareFlow} from '../../flow/model/flow/dosarSemnare-flow.model';
import {DosarFlow} from '../../flow/model/flow/dosar-flow.model';
import {ContractFlow} from '../../flow/model/flow/contract-flow.model';
import {PAAFlow} from '../../flow/model/flow/paa-flow.model';
import {CerereAchizitieFlow} from '../../flow/model/flow/cerereAchizitie-flow.model';
import {ProceduraAchizitieFlow} from '../../flow/model/flow/proceduraAchizitie-flow.model';

@Injectable()
export class PeService {
  private serviceUrl: string;

  constructor(private http: HttpClient) {
    this.serviceUrl = `${environment.baseApiUrl}/pe`;
  }

  public startFlowDosarSemnare(dosarSemnare: DosarSemnareFlow): Observable<NewDosarModel> {
    return this.http.post(`${this.serviceUrl}/dosarSemnare`, dosarSemnare, {
      observe: 'response'
    })
      .map((res: HttpResponse<any>) => res.body);
  }

  public startFlowDosar(dosar: DosarFlow): Observable<string> {

    return this.http.post(`${this.serviceUrl}/dosar`, dosar, {
      observe: 'response'
    })
      .map((res: HttpResponse<any>) => res.body);
  }

  public startFlowContract(contract: ContractFlow): Observable<string> {
    return this.http.post(`${this.serviceUrl}/contract`, contract, {
      observe: 'response'
    })
      .map((res: HttpResponse<any>) => res.body);
  }

  public startFlowPAA(paa: PAAFlow): Observable<string> {
    return this.http.post(`${this.serviceUrl}/paa`, paa, {
      observe: 'response'
    })
      .map((res: HttpResponse<any>) => res.body);
  }

  public startFlowCerereAchizitie(cerereAchizitie: CerereAchizitieFlow): Observable<string> {
    return this.http.post(`${this.serviceUrl}/cerereAchizitie`, cerereAchizitie, {
      observe: 'response'
    })
      .map((res: HttpResponse<any>) => res.body);
  }

  public startFlowProceduraAchizitie(proceduraAchizitie: ProceduraAchizitieFlow): Observable<any> {

    this.initProceduraAchizitie(proceduraAchizitie);
    return this.http.post(`${this.serviceUrl}/proceduraAchizitie`, proceduraAchizitie, {
      observe: 'response'
    })
        .map((res: HttpResponse<any>) => res.body);
  }

  private initProceduraAchizitie(proceduraAchizitie: ProceduraAchizitieFlow) {
  }
}
