import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {Attachment} from '../../../model/attachment.model';

@Component({
  selector: 'app-doc-preview-modal',
  templateUrl: './doc-preview-modal.component.html',
  styleUrls: ['./doc-preview-modal.component.scss']
})
export class DocPreviewModalComponent implements OnInit {
  @Input() url: string;
  @Input() attachment: Attachment;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  closeModal(reason: string = 'CLOSE') {
    this.activeModal.close(reason);
  }

  get isImage(): boolean {
    return !!(this.attachment && this.attachment.contentType && this.attachment.contentType.startsWith('image/'));
  }

  get fileName(): string {
    return (this.attachment && this.attachment.name) || '';
  }
}
