import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {MomentUtil} from '../../util/moment.util';
import { CalendarEvent, InstanceEvent } from '../model/event.model';

@Injectable()
export class EventsService {
  private serviceUrl: string;
  private eventDateFields = ['startDate', 'dueDate', 'completedDate'];

  constructor(
    private http: HttpClient) {
    this.serviceUrl = `${environment.baseApiUrl}/events`;
  }

  public getAll(): Observable<Array<InstanceEvent>> {
    return this.http.get(this.serviceUrl + '/all', {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body)
      .map((obj) => { obj = this.mapEventsDates(obj); return obj; });
  }

  /**
   * Handles obj and arrays.
   * @param events
   */
  private mapEventsDates(events: Array<InstanceEvent>): Array<InstanceEvent> {
    return events.map((d) => this.mapEventDates(d));
  }

  private mapEventDates(event: InstanceEvent): InstanceEvent {
    event = MomentUtil.mapDates(event, this.eventDateFields);
    return event;
  }

  public getCalendarEvents(events: Array<InstanceEvent>): Array<CalendarEvent> {
    return events.map((e) => {
      let title = `Cazul ${e.nud} (${e.flowDescription} - ${e.instanceDescription}) ${e.eventDescription}`;
      if (e.contextDescription) {
        title += ` (${e.contextDescription})`;
      }
      let start: Date, backgroundColor: string;
      if (e.completedDate) {
        start = e.completedDate;
        backgroundColor = 'green';
      } else {
        start = e.dueDate;
        if (e.displayType === 'THIRDPARTY') {
          backgroundColor = 'blue';
        } else {
          backgroundColor = 'red';
        }
      }
      return new CalendarEvent(e.id, e.key, title, start, backgroundColor);
    });
  }

}
