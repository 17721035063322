import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {User} from '../model/user.model';
import {Observable, Subscription} from 'rxjs';
import {CustomEventManager} from './core/custom-event-manager.service';
import {NotificationTemplate} from '../model/notification-template.model';
import {MomentUtil} from '../../util/moment.util';
import {Notification} from '../model/notification.model';

@Injectable()
export class NotificationService implements OnDestroy {
  public static readonly NOTIFICATIONS_REQUEST_EVENT: string = 'event.notifications.need.update';
  private POLLING_INTERVAL = 60 * 1000; // 1 min
  private serviceUrl = environment.baseApiUrl + '/notifications';

  private user: User;
  private currentTimer;
  private sub: Subscription;

  currentNotifications: Array<Notification> = [];

  constructor(private http: HttpClient, private eventManager: CustomEventManager) {
    this.sub = new Subscription();
    this.sub.add(this.eventManager.subscribe(NotificationService.NOTIFICATIONS_REQUEST_EVENT, (_) => {
      this.loadNotifications();
    }));
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.stopForUser(this.user);
  }

  startForUser(user: User) {
    if (!user) {
      return;
    }
    this.user = user;

    this.loadNotifications();

    // check again in 1 mins or so
    this.currentTimer = setTimeout(() => {
      this.loadNotifications();
    }, this.POLLING_INTERVAL);
  }

  stopForUser(user: User) {
    if (this.user && user && this.user.name === user.name) {
      this.user = undefined;
      this.currentNotifications = [];
      // stop
      if (this.currentTimer) {
        clearTimeout(this.currentTimer);
      }
    }
  }

  getNotificationTemplates(): Observable<Array<NotificationTemplate>> {
    return this.http.get(this.serviceUrl + `/templates`, {
      observe: 'response'
    })
    .map((res: HttpResponse<any>) => res.body);
  }

  getLastNotificationsForUser(): Observable<Array<Notification>> {
    return this.http.get(this.serviceUrl, {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body)
      .map((obj) => MomentUtil.mapDates(obj, ['created', 'updated']));
  }

  markNotificationSeen(notificationId: number): Observable<HttpResponse<any>> {
    return this.http.post(this.serviceUrl + '/' + notificationId, {}, {
      observe: 'response'
    })
    .map((res: HttpResponse<any>) => res.body);
  }

  getNotificationsCount() {
    return this.currentNotifications.length;
  }

  getUnseenNotificationsCount() {
    return this.currentNotifications.filter((n) => !n.sent).length;
  }

  getCurrentNotifications(): Array<Notification> {
    return this.currentNotifications;
  }

  private loadNotifications() {
    this.getLastNotificationsForUser().subscribe((notifications) => {
      if (notifications && notifications.length) {
        const updatedNotifications = [];
        notifications.forEach((newNotif) => {
          let exists = false;
          this.currentNotifications.forEach((curNotif) => {
            if (curNotif.id === newNotif.id) {
              exists = true;
            }
          });
          if (exists === false) {
            updatedNotifications.push(newNotif);
          }
        });
        updatedNotifications.push(...this.currentNotifications);
        this.currentNotifications = updatedNotifications;
      }
    });
  }
}
