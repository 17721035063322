import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FileUploader} from 'ng2-file-upload';
import {environment} from '../../../../../environments/environment';
import {CustomEventManager} from '../../../services/core/custom-event-manager.service';
import {StateStorageService} from '../../../services/core/state-storage.service';
import {FlowService} from '../../../services/flow.service';
import {ToastrService} from 'ngx-toastr';
import {UiLoaderService} from '../../../services/core/ui-loader.service';
import {Attachment} from '../../../model/attachment.model';
import {Step} from '../../../model/step.model';
import {DmsDocumentService} from '../../../services/dms-document.service';
import {DocType} from '../../../model/doc-type.model';

@Component({
  selector: 'app-attachment-upload-modal',
  templateUrl: './attachment-upload-modal.component.html',
  styleUrls: ['./attachment-upload-modal.component.scss']
})
export class AttachmentUploadModalComponent implements OnInit {
  @Input()
  attachment: Attachment;
  @Input()
  step: Step;

  systemDocTypes: DocType[] = [];

  uploader: FileUploader;
  UPLOAD_URL = environment.baseApiUrl + '/attachment/upload-revision';

  constructor(protected eventManager: CustomEventManager,
              protected storage: StateStorageService,
              protected flowService: FlowService,
              protected toastService: ToastrService,
              protected modalService: NgbModal,
              protected uiLoaderService: UiLoaderService,
              public documentService: DmsDocumentService,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    const token = this.storage.getAuthenticationToken();

    this.uploader = new FileUploader({
      queueLimit: 1,
      autoUpload: true,
      isHTML5: true,
      headers: [{ name: 'Authorization', value: 'Bearer ' + token }]
    });

    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
      this.uiLoaderService.requestStarted('uploader');
    };

    this.uploader.onCompleteItem = (item, response, status, headers) => {
      this.uiLoaderService.requestFinished('uploader');
      if (status !== 200) {
        let error;
        if (response && response.length > 0) {
          error = JSON.parse(response).error.message;
        } else {
          error = 'A aparut o eroare la incarcarea documentului!';
        }
        this.toastService.error(error, 'Eroare!');
        this.uploader.clearQueue();
      } else {
        const a = <Attachment>JSON.parse(response);
        this.uploader.clearQueue();
        this.toastService.info(`Fisierul ${item._file.name} a fost incarcat cu succes.`, 'Succes!');
        this.closeModal('SUCCESS');
      }
    };

    const user = this.storage.getCurrentUser();
    this.uploader.options.url = this.UPLOAD_URL
      + '?author=' + user.name
      + '&instanceId=' + this.step.instanceId
      + '&step=' + this.step.stepId
      + '&taskId=' + this.step.taskId
      + '&docType=' + this.attachment.type
      + '&dmsId=' + this.attachment.dmsId;

    this.documentService.getDocTypes().subscribe((docTypes) => {
      this.systemDocTypes = docTypes;
    });
  }

  closeModal(reason: string = 'CLOSE') {
    this.activeModal.close(reason);
  }

  getDocTypeLabel(key: string) {
    const found = this.systemDocTypes.filter((sd) => sd.name === key);
    if (found && found.length > 0) {
      return found[0].description;
    }
    return key;
  }
}
