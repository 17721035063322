import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FullCalendar } from 'primeng/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import roLocale from '@fullcalendar/core/locales/ro';
import { EventsService } from '../../services/events.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-events-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('eventsCalendar', {static: true}) fc: FullCalendar;

  events: any;
  calendarOptions = {};

  constructor(
    private eventsService: EventsService,
    public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.calendarOptions = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      locales: [roLocale],
      locale: 'ro',
      aspectRatio: 3,
      firstDay: 1,
      header: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek,timeGridDay'
      },
      editable: true,
      eventRender: this._eventRender,
      displayEventTime: false
    };
  }

  closeModal() {
    this.activeModal.close('CLOSE');
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
    this.eventsService.getAll().subscribe((events) => {
      this.events = this.eventsService.getCalendarEvents(events);
    });
  }

  // This callback can be used to change event rendering (modifying the title, adding a description)
  _eventRender(info) {
    console.log(info);
    // (info.el as Element).querySelector('span.fc-title').after(info.event.extendedProps.key);
  }

}
