import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {CaseDetails} from '../model/case-details.model';
import {CaseFilter} from '../model/case-filter.model';
import {FilteredCases} from '../model/filtered-cases.model';
import {NotificationService} from './notification.service';
import {CustomEventManager} from './core/custom-event-manager.service';
import {Case, CaseDetailsModel} from '../model/case.model';
import {CaseMetadata} from "../model/dialog/case-metadata";

@Injectable()
export class CaseService {
  private serviceUrl: string;

  constructor(
    private http: HttpClient,
    private eventManager: CustomEventManager) {
    this.serviceUrl = `${environment.baseApiUrl}/case`;
  }

  dispatchNewCase(payload: any): Observable<Object> {
    return this.http.post(`${this.serviceUrl}/checkin-dispatcher`, payload, {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body);
  }

  public getCase(nud: string): Observable<CaseDetailsModel> {
    this.eventManager.broadcast({name: NotificationService.NOTIFICATIONS_REQUEST_EVENT});
    return this.http.get(`${this.serviceUrl}/details/${nud}`, {observe: 'response'})
        .map((res: HttpResponse<any>) => res.body);
  }

  getAll(filter: CaseFilter): Observable<FilteredCases> {
    this.eventManager.broadcast({name: NotificationService.NOTIFICATIONS_REQUEST_EVENT});
    return this.http.post(this.serviceUrl + '/all', filter, {
        observe: 'response'
      })
      .map((res: HttpResponse<any>) => res.body);
  }

  public close(collectionId: string, metadata: CaseMetadata = {}): Observable<CaseDetails> {
    return this.http.post(`${this.serviceUrl}/close/${collectionId}`, metadata, {
      observe: 'response'
    })
        .map((res: HttpResponse<any>) => res.body);
  }

  public delete(nud: string): Observable<CaseDetails> {
    return this.http.post(`${this.serviceUrl}/delete/${nud}`, null, {
      observe: 'response'
    })
      .map((res: HttpResponse<any>) => res.body);
  }

  public updateStatus(nud: string, status: string): Observable<CaseDetails> {
    return this.http.post(`${this.serviceUrl}/status/${nud}/${status}`, {}, {
      observe: 'response'
    }).map((res: HttpResponse<any>) => res.body);
  }
}
