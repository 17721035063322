import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs/Observable";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class UiLoaderService {
  private nrActiveRequests = 0;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public isLoading$: Observable<boolean> = this.loadingSubject.asObservable();

  constructor(private ngxUILoader: NgxUiLoaderService) {
  }

  requestStarted(name: string) {
    if (this.nrActiveRequests === 0) {
      this.ngxUILoader.start();
    }
    this.nrActiveRequests++;
  }

  requestFinished(name: string) {
    this.nrActiveRequests--;
    if (this.nrActiveRequests < 0) {
      this.nrActiveRequests = 0;
    }
    // if (this.nrActiveRequests === 0) {
      this.ngxUILoader.stop();
    // }
  }
}
