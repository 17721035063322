import {Component, OnInit} from '@angular/core';
import {IOption} from 'ng-select';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {OrganizationService} from '../../../shared/services/organization.service';
import {Delegation} from '../../../shared/model/delegation.model';
import {MomentUtil} from '../../../util/moment.util';
import {DelegationService} from '../../../shared/services/delegation.service';
import {ToastrService} from 'ngx-toastr';
import {AccountService} from '../../../shared/services/account.service';
import {FilterUtils, SortEvent} from 'primeng/api';

@Component({
  selector: 'app-delegation',
  templateUrl: './delegation.component.html',
  styleUrls: ['./delegation.component.scss']
})
export class DelegationComponent implements OnInit {

  normalUserDelegation = true;
  initialUser: any;
  delegatedUser: any;
  selectedDelegation: Delegation;
  allDelegations: Array<Delegation> = [];
  allDirectionsMembers: Array<IOption> = [];
  delegationsCols: any[];
  delegation = new Delegation(null, null, null,
    MomentUtil.today().toDate(),
    MomentUtil.inAdvance(7).toDate(),
    null, null, true);

  constructor(
    public activeModal: NgbActiveModal,
    private accountService: AccountService,
    private organizationService: OrganizationService,
    private delegationService: DelegationService,
    private toastService: ToastrService) {
  }

  ngOnInit() {
    this.organizationService.getAllUsers().subscribe((allMembers) => {
      const tempArrayOpts = [];
      allMembers.forEach(user => {
        tempArrayOpts.push(this.organizationService.mapUserToOption(user));
      });
      this.allDirectionsMembers = tempArrayOpts;
      this.accountService.getAccount().subscribe(u => {
        if (u.supervisor) {
          this.normalUserDelegation = false;
        } else {
          this.normalUserDelegation = true;
          this.initialUser = tempArrayOpts.find(m => m.value === u.name);
        }
      });
    });
    this.loadDelegations();
    this.delegationsCols = [
      { header: 'Status', field: 'status', width: '10%', pSortableColumnDisabled: true, isDate: false },
      { header: 'De la', field: 'initialUser', width: '15%', isDate: false },
      { header: 'La', field: 'delegatedUser', width: '15%', isDate: false },
      { header: 'De la', field: 'startDate', width: '15%', isDate: true },
      { header: 'La', field: 'endDate', width: '15%' , isDate: true },
      { header: 'Creat', field: 'created', width: '20%', isDate: true },
      { header: 'Asignat', field: 'reassign', width: '10%', isDate: false }];

    // custom filter for boolean reassign
    FilterUtils['customReassign'] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }
      if (value === undefined || value === null) {
        return false;
      }

      if (value === true && filter === 'DA') {
        return true;
      }
      if (value === false && filter === 'NU') {
        return true;
      }
      return false;
    };
  }

  customFormat(rowData: any, col: any) {
    if (col.isDate) {
      if (col.field === 'startDate' || col.field === 'endDate') {
        return MomentUtil.formatDate(rowData[col.field]);
      }
      return MomentUtil.formatDateTime(rowData[col.field]);
    } else if (col.field === 'reassign') {
      if (rowData[col.field] === true) {
        return 'DA';
      } else {
        return 'NU';
      }
    } else if (col.field === 'status') {
      return '';
    } else {
      return rowData[col.field];
    }
  }

  closeModal() {
    this.activeModal.close('CLOSE');
  }

  public selectedInitial(option: any) {
    this.initialUser = option;
  }

  public removedInitial(option: any) {
    this.initialUser = null;
  }

  public selectedDelegated(option: any) {
    this.delegatedUser = option;
  }

  public removedDelegated(option: any) {
    this.delegatedUser = null;
  }

  onTabClick(event) {
    this.selectedDelegation = null;
  }

  onStartDateSelected(event: any) {
    if (this.delegation.endDate && !MomentUtil.isAfter(this.delegation.startDate, this.delegation.endDate)) {
      this.delegation.endDate = MomentUtil.getMoment(this.delegation.startDate).add(1, 'day').toDate();
    }
  }

  saveDelegation() {
    this.delegation.initialUser = this.initialUser.value;
    this.delegation.delegatedUser = this.delegatedUser.value;
    this.delegationService.create(this.delegation).subscribe(
      () => {
        this.toastService.success('Delegarea a fost creata cu succes!');
        this.loadDelegations();
      });
  }

  onRefreshDelegationsClick(event) {
    this.loadDelegations();
  }

  onDelegationDeleteClick(event) {
    if (this.selectedDelegation) {
      this.delegationService.delete(this.selectedDelegation.id).subscribe(() => {
        this.toastService.success('Delegarea a fost stearsa cu succes!');
        this.loadDelegations();
      });
    }
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else {
        if (event.field === 'initialUser') {
          result = value1.localeCompare(value2);
        } else if (event.field === 'delegatedUser') {
          result = value1.localeCompare(value2);
        } else if (event.field === 'startDate') {
          result = MomentUtil.compareDates(value1, value2);
        } else if (event.field === 'endDate') {
          result = MomentUtil.compareDates(value1, value2);
        } else if (event.field === 'created') {
          result = MomentUtil.compareDates(value1, value2);
        } else if (event.field === 'reassign') {
          result = value1 === value2;
        } else {
          return 0;
        }
      }
      return (event.order * result);
    });
  }

  getDelegationStatus(rowData) {
    const currentDate = new Date();
    let status = 'NotStarted';
    if (MomentUtil.isBefore(currentDate, rowData['endDate'])) {
      status = 'Ended';
    } else if (MomentUtil.isAfter(rowData['startDate'], currentDate)) {
      status = 'Started';
    }
    return status;
  }

  private loadDelegations() {
    this.delegationService.getAll().subscribe((allDelegations) => {
      this.allDelegations = allDelegations;
    });
  }

}
