import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';

@Injectable()
export class SwalService {
    static noop(): void {}

    handle(okCallback?, notOkCallback?, swaltitle?, swaltext?, swaltype?, okText?, cancelText?) {
        Swal.fire({
            title: swaltitle,
            text: swaltext,
            showCancelButton: true,
            confirmButtonText: okText ? okText : 'Da',
            cancelButtonText: cancelText ? cancelText : 'Renunță',
            buttonsStyling: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.value) {
                okCallback();
            } else {
                notOkCallback();
            }
        }).catch(SwalService.noop);
    }

    defaultConfirmation(swaltext?: string, okCallback?: Function, notOkCallback: Function = SwalService.noop) {
        this.handle(okCallback, notOkCallback, 'Confirmare acțiune', swaltext, 'warning');
    }

    deleteAccountConfirmation(swaltext?: string, okCallback?: Function, notOkCallback: Function = SwalService.noop) {
        this.handle(okCallback,
          notOkCallback,
          'By deleting your account you will loose all your campaign data. Do you want to continue?',
          swaltext, 'warning');
    }

    success(title: string, message: string, okCallback?) {
        Swal.fire({
            title,
            text: message,
            // type: 'success',
            buttonsStyling: false
        }).then((result) => {
            if (okCallback) {
                okCallback();
            }
        });
    }

    error(message: string, title?: string, okCallback?) {
        Swal.fire({
            title: title ? title : 'Error',
            text: message,
            // type: 'error',
            buttonsStyling: false
        }).then((result) => {
            if (okCallback) {
                okCallback();
            }
        });
    }

    info(htmlContent: string, title?: string) {
        Swal.fire({
            // type: 'info',
            html: htmlContent,
            title,
            buttonsStyling: false
        }).then((result) => {
        });
    }

}
