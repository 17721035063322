function _window(): any {
  // return the global native browser window object
  return window;
}

export class WindowRefService {

  constructor() {
  }

  static closeWindow() {
    setTimeout(() => {
      this.close();
    }, 2000);
  }

  static close() {
    _window().close();
  }

  static goToExternalURL(externalURL: string) {
    _window().location = externalURL;
  }

  logoutRedirect() {
  }
}
