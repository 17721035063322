import {StepConfigDetails} from './step-config-details.model';

export class Step {
  constructor(
    public instanceId: string,
    public caseId: string,
    public stepId: string,
    public taskId: string,
    public acquiredBy: string,
    public config: StepConfigDetails) {
  }
}
