import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {FlowAudit} from '../model/audit.model';
import {Observable} from 'rxjs/Observable';
import {MomentUtil} from '../../util/moment.util';

@Injectable()
export class AuditService {
  private serviceUrl: string;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.baseApiUrl + '/audit';
  }

  getAllAuditsByCaseId(caseId: any): Observable<Array<FlowAudit>> {
    return this.http.get(this.serviceUrl + '/case/' + caseId, {
      observe: 'response'
    })
    .map((res: HttpResponse<any>) => res.body)
    .map((obj) => MomentUtil.mapDates(obj, ['created', 'update']));
  }

}
