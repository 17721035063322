export class User {
  constructor(
    public name: string,
    public email: string,
    public digitalSignatureName: string,
    public digitalSignaturePassword: string,
    public handSignatureName: string,
    public nume: string,
    public prenume: string,
    public telefon: any[],
    public institutie: string,
    public functie: boolean,
    public showNotificationsUI: boolean,
    public password?: string,
    public authorities?: string[],
    public compartiment?: string,
    public urlRaportare?: string,
    public admin?: boolean,
    public supervisor?: boolean,
  ) {}
}
