export class UserComment {
  constructor(
    public instanceId: string,
    public taskId: string,
    public stepName: string,
    public actor: string,
    public value: string,
    public created: Date,
    public updated: Date) {
  }
}
