import {EMPTY, Observable, throwError} from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {StateStorageService} from '../services/core/state-storage.service';
import {Injectable} from '@angular/core';
import {catchError, tap} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie';
import {Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private storage: StateStorageService,
    private cookieService: CookieService,
    private router: Router
  ) {}

  private handleAuthError(err: HttpErrorResponse, url: string): Observable<any> {

    if (url.includes('reset-password')) {
      return throwError(err);
    }

    if (err.status === 403) {
      this.router.navigate(['/login']);
      return EMPTY;
    }
    if (err.status === 401) {
      this.router.navigate(['/login/bad']);
      return EMPTY;
    }

    return throwError(err);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('reset-password')) {
      return next.handle(request);
    }

    const token = this.storage.getAuthenticationToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        }
      });
    }

    return next.handle(request).pipe(
      tap({
        next: (evt) => {
          if (evt instanceof HttpResponse) {
            const newToken = this.cookieService.get('jwt-authentication');
            if (newToken && newToken.length) {
              this.storage.storeAuthenticationToken(newToken);
              this.cookieService.remove('jwt-authentication');
            }
          }
        }
      }),
      catchError((err: HttpErrorResponse) => this.handleAuthError(err, request.url))
    );
  }
}
