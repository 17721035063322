import {ToolbarButtons} from './toolbar-buttons.model';
import {ToolbarButton} from './toolbar-button.model';

export class UIConfig {
  constructor(
    public toolbarButtons: ToolbarButtons,
    public allVisible: boolean,
    public commentsReadOnly: boolean
  ) {
  }

  public static initToolbarConfig(toolbarButtonsConfig: ToolbarButtons) {
    const defc = UIConfig.defaultToolbarConfig();
    if (toolbarButtonsConfig) {
      if (toolbarButtonsConfig.approve) {
        Object.assign(defc.approve, toolbarButtonsConfig.approve);
      }
      if (toolbarButtonsConfig.reject) {
        Object.assign(defc.reject, toolbarButtonsConfig.reject);
      }
      if (toolbarButtonsConfig.custom) {
        defc.custom = new ToolbarButton(null, null, null, null);
        Object.assign(defc.custom, toolbarButtonsConfig.custom);
      }
    }
    return defc;
  }

  public static defaultToolbarConfig() {
    const defc = new ToolbarButtons(
      new ToolbarButton('Aprob', null),
      new ToolbarButton('NU Aprob', null),
      null
    );
    return defc;
  }
}
