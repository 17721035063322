import {Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {TokenResolverService} from './shared/services/token-resolver.service';
import {TokenValidationGuard} from "./reset-password/guards/token-validation.guard";

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'tasklist/home',
    pathMatch: 'full'
  },
  {
    path: 'editor',
    loadChildren: './editor/editor.module#EditorModule'
  },
  {
    path: 'registry',
    loadChildren: './registry/registry.module#RegistryModule'
  },
  {
    path: 'nomenclator',
    loadChildren: './nomenclator/nomenclator.module#NomenclatorModule'
  },
  {
    path: 'flow',
    loadChildren: './flow/flow.module#FlowModule'
  },
  {
    path: 'tasklist',
    loadChildren: './tasklist/tasklist.module#TasklistModule'
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [TokenValidationGuard],
    resolve: {
      token: TokenResolverService
    }
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'login/:type',
    component: LoginComponent
  }
];
