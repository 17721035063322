import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { Task } from '../model/task.model';
import { TaskFilter } from '../model/task-filter.model';
import { FilteredTasks } from '../model/filtered-tasks.model';
import { MomentUtil } from '../../util/moment.util';
import { CustomEventManager } from './core/custom-event-manager.service';
import { NotificationService } from './notification.service';

@Injectable()
export class TaskService {
  private serviceUrl: string;
  private taskDateFields = ['assignedDate', 'createdDate', 'updatedDate', 'endDate', 'dueDate'];

  constructor(
    private http: HttpClient,
    private eventManager: CustomEventManager) {
    this.serviceUrl = `${environment.baseApiUrl}/task`;
  }

  public getDetails(taskId: string): Observable<Task> {
    return this.http.get(`${this.serviceUrl}/details/${taskId}`, {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body)
      .map((obj) => this.mapTaskDates(obj));
  }

  getAll(filter: TaskFilter): Observable<FilteredTasks> {
    this.eventManager.broadcast({name: NotificationService.NOTIFICATIONS_REQUEST_EVENT});
    return this.http.post(this.serviceUrl + '/all', filter, {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body)
      .map((obj) => { obj.tasks = this.mapTasksDates(obj.tasks); return obj; });
  }

  assignTask(taskIds: string[], toUser: string, keepOriginal: boolean) {
    const payload = {taskIds, toUser, keepOriginal};
    return this.http.post(this.serviceUrl + '/assignment', payload, {observe: 'response'})
        .map((res: HttpResponse<any>) => res.body);
  }

  /**
   * Handles obj and arrays.
   * @param tasks
   */
  private mapTasksDates(tasks: Array<Task>): Array<Task> {
    return tasks.map((t) => this.mapTaskDates(t));
  }

  private mapTaskDates(task: Task): Task {
    task = MomentUtil.mapDates(task, this.taskDateFields);
    task.children = MomentUtil.mapDates(task.children, this.taskDateFields);
    return task;
  }

}
