export class InstanceEvent {
  constructor(
    public id: number,
    public key: string,
    public startDate: Date,
    public dueDate: Date,
    public completedDate: Date,
    public displayType: string,
    public eventDescription: string,
    public flowDescription: string,
    public instanceDescription: string,
    public contextDescription: string,
    public nud: string
    ) {}
}

export class CalendarEvent {
  constructor(
    public id: number,
    public key: string,
    public title: string,
    public start: Date,
    public backgroundColor: string
  ) {
  }
}
