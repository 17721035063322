import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {FileItem, FileUploader, ParsedResponseHeaders} from 'ng2-file-upload';
import {environment} from '../../../../../environments/environment';
import {StateStorageService} from '../../../services/core/state-storage.service';
import {UiLoaderService} from '../../../services/core/ui-loader.service';
import {InstanceService} from '../../../services/instance.service';
import {AttachmentService} from '../../../services/attachment.service';
import {Attachment} from '../../../model/attachment.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DocPreviewModalComponent} from '../doc-preview-modal/doc-preview-modal.component';
import {OFERTA} from "../../../constants";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {
  @Input() eid: number;
  @Input() entityType: string;
  @Input() showFileUpload = true;
  @Input() showBtn = false;
  @Output() filesReady = new EventEmitter<FileItem[]>();
  @Output() uploadComplete = new EventEmitter<void>();

  uploader: FileUploader;
  UPLOAD_URL = environment.baseApiUrl + '/attachment/upload';
  DOWNLOAD_URL = environment.baseApiUrl + '/attachment/download';
  PREVIEW_URL = environment.baseApiUrl + '/attachment/preview';
  attachments: Attachment[] = [];
  instanceId: string;

  constructor(
    private toastrService: ToastrService,
    private stateStorageService: StateStorageService,
    private uiLoaderService: UiLoaderService,
    private instanceService: InstanceService,
    private attachmentService: AttachmentService,
    private modalService: NgbModal,
  ) {
    this.uploader = new FileUploader({
      url: this.UPLOAD_URL,
      autoUpload: false,
      isHTML5: true,
      removeAfterUpload: false
    });
  }

  ngOnInit() {
    const token = this.stateStorageService.getAuthenticationToken();
    const step = this.stateStorageService.getCurrentStep();
    const user = this.stateStorageService.getCurrentUser();
    this.instanceId = step.instanceId;
    this.initializeUploader(token, step, user);
    this.loadAttachments();
  }

  private initializeUploader(token: string, step: any, user: any) {
    this.uploader.setOptions({
      headers: [{ name: 'Authorization', value: 'Bearer ' + token }]
    });

    this.uploader.onBeforeUploadItem = (item: FileItem) => {
      item.withCredentials = false;
      this.uiLoaderService.requestStarted('uploader');

      item.url = this.UPLOAD_URL
        + '?author=' + encodeURIComponent(user.name)
        + '&instanceId=' + encodeURIComponent(step.instanceId)
        + '&step=' + encodeURIComponent(step.stepId)
        + '&taskId=' + encodeURIComponent(step.taskId)
        + '&docType=' + encodeURIComponent(this.entityType)
        + '&entityType=' + encodeURIComponent(this.entityType)
        + '&eid=' + encodeURIComponent(this.eid.toString());
    };

    this.uploader.onCompleteItem = (item: FileItem, response: string, status: number, _: ParsedResponseHeaders) => {
      this.uiLoaderService.requestFinished('uploader');
      if (status !== 200) {
        let error = 'A apărut o eroare la încărcarea documentului!';
        if (response && response.length > 0) {
          try {
            error = JSON.parse(response).error.message;
          } catch (e) {
            console.error('Error parsing response:', e);
          }
        }
        this.toastrService.error(error, 'Eroare!');
      } else {
        this.toastrService.success(`Fișierul ${item.file.name} a fost încărcat cu succes.`, 'Succes!');
        this.loadAttachments();
      }
    };

    this.uploader.onCompleteAll = () => {
      this.filesReady.emit(this.uploader.queue);
      this.uploader.clearQueue();
      this.uploadComplete.emit();
    };
  }

  private loadAttachments() {
    this.attachmentService.getAttachmentsByEidAndEntityType(this.eid, this.entityType)
      .subscribe(
        (attachments) => {
          this.attachments = attachments;
        },
        (_) => {
          this.toastrService.error('A apărut o eroare', 'Error');
        }
      );
  }

  getFiles(): FileItem[] {
    return this.uploader.queue;
  }

  uploadFiles() {
    if (this.uploader.queue.length > 0) {
      this.uploader.uploadAll();
    } else {
      this.toastrService.warning('Selectați cel puțin un fișier', 'Warning');
    }
  }

  removeFile(item: FileItem) {
    item.remove();
    this.uploader.queue = this.uploader.queue.filter(queueItem => queueItem !== item);
    this.filesReady.emit(this.uploader.queue);
  }

  clearQueue() {
    this.uploader.clearQueue();
    this.filesReady.emit(this.uploader.queue);
  }

  downloadAttachment(attachment: Attachment) {
    window.open(this.DOWNLOAD_URL + '?dmsId=' + attachment.dmsId, '_blank');
  }

  previewAttachment(attachment: Attachment) {
    const modalRef = this.modalService.open(DocPreviewModalComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.url =
      (attachment.contentType === 'application/pdf' ? this.DOWNLOAD_URL : this.PREVIEW_URL)
      + '?dmsId=' + attachment.dmsId;
    modalRef.result.then(
      () => { },
      () => { }
    );
  }

  removeAttachment(attachment: Attachment) {
    this.uiLoaderService.requestStarted('delete-file');
    this.attachmentService.deleteByEidAndEntityTypeAndName(this.eid, this.entityType, attachment.name)
      .pipe(finalize(() => this.uiLoaderService.requestFinished('delete-file')))
      .subscribe(
        () => {
          this.toastrService.success('Fișierul a fost șters cu succes', 'Success');
          this.loadAttachments();
        },
        (_) => {
          this.toastrService.error('A apărut o eroare la ștergerea fișierului', 'Error');
        }
      );
  }

  fileSelected(event: any) {
    const files: FileList = event.target.files;

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileItem = new FileItem(this.uploader, file, this.uploader.options);
        this.uploader.queue.push(fileItem);
      }
      this.filesReady.emit(this.uploader.queue);
    } else {
      console.warn('Nu ati selectat nici un fisier');
    }
  }

  uploadRevision(attachment: Attachment) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.click();

    fileInput.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const file = target.files[0];
        const params = {
          author: this.stateStorageService.getCurrentUser().name,
          instanceId: this.instanceId,
          step: this.stateStorageService.getCurrentStep().stepId,
          taskId: this.stateStorageService.getCurrentStep().taskId,
          dmsId: attachment.dmsId,
          docType: attachment.type
        };

        this.uiLoaderService.requestStarted('uploadRevision');
        this.attachmentService.uploadRevision(file, params).subscribe(
          (_: Attachment) => {
            this.uiLoaderService.requestFinished('uploadRevision');
            this.toastrService.success('Versiunea nouă a fost încărcată cu succes', 'Succes');
            this.loadAttachments();
          },
          (error) => {
            this.uiLoaderService.requestFinished('uploadRevision');
            this.toastrService.error('Eroare la încărcarea noii versiuni', 'Eroare');
            console.error('Error uploading revision:', error);
          }
        );
      }
    };
  }

  protected readonly OFERTA = OFERTA;
}
