import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {FlowConfig} from '../model/step-config-list.model';
import {ComponentDefaultConfig} from '../model/component-default-config';
import { NotificationService } from './notification.service';
import { CustomEventManager } from './core/custom-event-manager.service';
import {ActionDefaultConfig} from '../model/action-default-config';

@Injectable()
export class EditorService {
  private serviceUrl: string;

  constructor(private http: HttpClient,
              private eventManager: CustomEventManager) {
    this.serviceUrl = environment.baseApiUrl + '/configadmin';
  }

  getFlowConfig(flow: string, refresh?: boolean): Observable<FlowConfig> {
    this.eventManager.broadcast({name: NotificationService.NOTIFICATIONS_REQUEST_EVENT});
    let urlPath = `/export/single/${flow}`;
    if (refresh) {
      urlPath += '?refresh=true';
    }
    return this.http.get(this.serviceUrl + urlPath, {
      observe: 'response'
    })
    .map((res: HttpResponse<any>) => res.body);
  }

  saveFlowConfig(flowConfig: FlowConfig) {
    return this.http.post(this.serviceUrl + '/import/multiple', flowConfig, {
      observe: 'response'
    })
    .map((res: HttpResponse<any>) => res.body);
  }

  getComponentDefaultConfigs(): Observable<Array<ComponentDefaultConfig>> {
    return this.http.get(this.serviceUrl + '/templates/component', {
      observe: 'response'
    })
    .map((res: HttpResponse<any>) => res.body);
  }

  getActionDefaultConfigs(): Observable<Array<ActionDefaultConfig>> {
    return this.http.get(this.serviceUrl + '/templates/action', {
      observe: 'response'
    })
      .map((res: HttpResponse<any>) => res.body);
  }
}
