import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../model/user.model';
import { StateStorageService } from './core/state-storage.service';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private serviceUrl: string;

  constructor(
    private http: HttpClient,
    private storage: StateStorageService
  ) {
    this.serviceUrl = `${environment.baseApiUrl}/account`;
  }

  getFunctii(): Observable<any[]> {
    return this.http.get<any[]>(`${this.serviceUrl}/functii`);
  }

  getInstitutii(): Observable<any[]> {
    return this.http.get<any[]>(`${this.serviceUrl}/institutii`);
  }

  public getAccount(): Observable<User> {
    const currentUser = this.storage.getCurrentUser();
    if (!currentUser) {
      return this.http.get<User>(this.serviceUrl, { observe: 'response' }).pipe(
        map((res: HttpResponse<User>) => res.body),
        tap(user => this.storage.storeCurrentUser(user))
      );
    } else {
      return of(currentUser);
    }
  }

  changePassword(currentPassword: string, newPassword: string): Observable<any> {
    return this.http.post(`${this.serviceUrl}/change-password`, { currentPassword, newPassword });
  }

  public saveAccount(acc: User): Observable<User> {
    return this.http.put<User>(this.serviceUrl, acc, {
      observe: 'response'
    }).pipe(
      map((res: HttpResponse<User>) => res.body),
      tap(user => this.storage.storeCurrentUser(user))
    );
  }
}
