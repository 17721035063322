import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {Attachment} from '../model/attachment.model';

@Injectable()
export class AttachmentService {
  private serviceUrl: string;

  constructor(private http: HttpClient) {
    this.serviceUrl = `${environment.baseApiUrl}/attachment`;
  }

  getDocsForStep(instanceId: any, step: any): Observable<Array<Attachment>>  {
    return this.http.get(`${this.serviceUrl}/instance/${instanceId}/step/${step}/docs`,  {
      observe: 'response'
    })
      .map((res: HttpResponse<any>) => res.body);
  }

  getAttachmentsByEidAndEntityType(eid: number, entityType: string): Observable<Attachment[]> {
    return this.http.get<Attachment[]>(`${this.serviceUrl}/entity/${eid}/type/${entityType}`);
  }

  uploadRevision(file: File, params: any): Observable<Attachment> {
    const formData = new FormData();
    formData.append('file', file);

    Object.keys(params).forEach(key => {
      formData.append(key, params[key]);
    });

    return this.http.post<Attachment>(`${this.serviceUrl}/upload-revision`, formData);
  }

  getApproveDocsForStep(instanceId: any, step: any): Observable<Array<Attachment>>  {
    return this.http.get(`${this.serviceUrl}/instance/${instanceId}/step/${step}/approve-docs`,  {
      observe: 'response'
    })
    .map((res: HttpResponse<any>) => res.body);
  }

  getShowDocsForStep(instanceId: any, step: any): Observable<Array<Attachment>>  {
    return this.http.get(`${this.serviceUrl}/instance/${instanceId}/step/${step}/show-docs`,  {
      observe: 'response'
    })
      .map((res: HttpResponse<any>) => res.body);
  }

  delete(dmsId: any): Observable<Attachment>  {
    return this.http.delete(`${this.serviceUrl}/${dmsId}`, {
      observe: 'response'
    })
    .map((res: HttpResponse<any>) => res.body);
  }
  deleteByEidAndEntityTypeAndName(eid: number, entityType: string, name: string): Observable<void> {
    const payload = { eid, entityType, name };
    return this.http.request<void>('DELETE', `${this.serviceUrl}/delete/type-name`, {
      body: payload,
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }
}
