import { Component, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { StateStorageService } from './shared/services/core/state-storage.service';
import { AccountService } from './shared/services/account.service';
import { NavigationEnd, Router } from '@angular/router';
import { CustomEventManager } from './shared/services/core/custom-event-manager.service';
import { User } from './shared/model/user.model';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from './shared/services/notification.service';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { filter } from "rxjs/operators";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  user: User;

  constructor(
    private accountService: AccountService,
    private cookieService: CookieService,
    private storage: StateStorageService,
    private eventManager: CustomEventManager,
    private router: Router,
    private toastService: ToastrService,
    private notificationService: NotificationService,
    private loaderService: NgxUiLoaderService
  ) {}

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (!event.url.includes('reset-password') && !event.url.includes('login')) {
        this.initializeApp();
      }
    });
  }

  private initializeApp() {
    this.accountService.getAccount().subscribe(
      (user) => {
        this.storage.storeCurrentUser(user);
        this.user = user;
        if (this.user && this.user.showNotificationsUI) {
          this.notificationService.startForUser(this.user);
        }
      },
      (err: HttpErrorResponse) => {
        this.loaderService.stop();
        if (err.status === 401 || err.status === 403) {
          // Redirect to login for unauthorized or forbidden errors
          this.router.navigate(['/login']);
        } else {
          // Handle other errors as needed
          console.error('Error initializing app:', err);
        }
      }
    );
  }

  ngOnDestroy() {
    // Your destroy logic here
  }
}
