import {Observable} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {UiLoaderService} from '../services/core/ui-loader.service';

@Injectable()
export class UILoaderInterceptor implements HttpInterceptor  {
  private EXCLUDE_PATH_FRAGMENTS = [
    '/api/flow/test-runner/state',
    '/api/notifications'
  ];

  constructor(
      private uiLoaderService: UiLoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.exclude(request.url)) {
      this.uiLoaderService.requestStarted(request.url + request.method);
    }

    return next.handle(request).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          if (!this.exclude(request.url)) {
            this.uiLoaderService.requestFinished(request.url + request.method);
          }
        } else if (evt instanceof HttpErrorResponse) {
          if (!this.exclude(request.url)) {
            this.uiLoaderService.requestFinished(request.url + request.method);
          }
        }
      })
    );
  }

  private exclude(url: string) {
    let exclude = false;
    this.EXCLUDE_PATH_FRAGMENTS.forEach((eu) => {
      if (url.indexOf(eu) > 0) {
        exclude = true;
      }
    });
    return exclude;
  }
}
