export const TASK_GROUP_VOTE = 'groupvote';
export const TASK_OUTCOME_APPROVE = 'APPROVE';
export const TASK_OUTCOME_APPROVE_CUSTOM = 'APPROVE_CUSTOM';
export const TASK_OUTCOME_REJECT = 'REJECT';

// INSTANCE VARIABLES
export const VAR_START_DOC = 'START_DOC';

export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_SUPERVISOR = 'ROLE_SUPERVISOR';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_REGISTRATURA = 'ROLE_REGISTRATURAGENERALA';
export const MIN_PASS_LEN = 8;

// Entity types for upload component
export const PROCEDURA = 'PROCEDURA';
export const MODIFICARE = 'MODIFICARE';
export const MESAJ_INTREBARE = 'MESAJ_INTREBARE';
export const MESAJ_RASPUNS = 'MESAJ_RASPUNS';
export const EVALUARE_INTREBARE = 'EVALUARE_INTREBARE';
export const EVALUARE_RASPUNS = 'EVALUARE_RASPUNS';
export const OFERTA = 'OFERTA';
export const EVALUARE = 'EVALUARE';
export const INTREBARE = 'intrebare';
export const REZULTAT = 'REZULTAT';
