import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import {StepConfigDetails} from '../model/step-config-details.model';
import {Observable} from 'rxjs/Observable';
import {StepOutcome} from '../model/step-outcome.model';
import {DocType} from '../model/doc-type.model';
import {FlowType} from '../model/flow-type.model';

@Injectable()
export class FlowService {
  private serviceUrl: string;

  constructor(private http: HttpClient) {
    this.serviceUrl = environment.baseApiUrl + '/flow';
  }

  addStepOutcome(res: StepOutcome): Observable<Object> {
    return this.http.post(`${this.serviceUrl}/outcome`, res, {observe: 'response'});
  }

  loadStepConfig(instanceId: string, stepId: string): Observable<StepConfigDetails> {
    return this.http.get(`${this.serviceUrl}/config/instance/${instanceId}/step/${stepId}`, {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body);
  }

  getResultsForStep(instanceId: string, stepId: string): any {
    return this.http.get(`${this.serviceUrl}/results/${instanceId}/step/${stepId}`);
  }

  getDocumentTypes(): Observable<Map<string, Array<DocType>>> {
    return this.http.get(`${this.serviceUrl}/doctype/all`, {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body);
  }

  getFlowTypes(): Observable<Array<FlowType>> {
    return this.http.get(`${this.serviceUrl}/flowtype/all`, {observe: 'response'})
      .map((res: HttpResponse<any>) => res.body);
  }
}
