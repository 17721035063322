import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PasswordResetService {
  private serviceUrl: string;

  constructor(private http: HttpClient) {
    this.serviceUrl = `${environment.baseApiUrl}/password`;
  }

  requestPasswordReset(email: string): Observable<any> {
    return this.http.post(`${this.serviceUrl}/forgot`, { email }, {
      responseType: 'text'
    });
  }

  resetPassword(token: string, newPassword: string): Observable<string> {
    return this.http.post(`${this.serviceUrl}/reset`, { token, newPassword }, {
      responseType: 'text'
    });
  }
  validateToken(token: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.serviceUrl}/validate-token`, { token });
  }
}
