import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-doc-preview-modal',
  templateUrl: './doc-preview-modal.component.html',
  styleUrls: ['./doc-preview-modal.component.scss']
})
export class DocPreviewModalComponent implements OnInit {
  @Input()
  url: string;

  constructor(
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  closeModal(reason: string = 'CLOSE') {
    this.activeModal.close(reason);
  }
}
