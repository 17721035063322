import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/observable/of';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class LoginService {
  private serviceUrl: string;

  constructor(
    private http: HttpClient) {
    this.serviceUrl = `${environment.baseApiUrl}/login`;
  }

  login(credentials): Observable<any> {
    return this.http.post(this.serviceUrl + '/authenticate', {
      username: credentials.username,
      password: credentials.password
    }, httpOptions);
  }
}
